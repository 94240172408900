import { getUserData, isUserLoggedIn } from '@/auth/utils'
// Import the Vuex store
import store from '@/store' // Adjust the path as needed

const guardCustom = (next, myAbilitySubject = [], myAbilityAction = []) => {
  console.log('[guardCustom] protections')
  if (isUserLoggedIn()) {
    const { visitor_role, visitor_ability } = getUserData()
    if (
      visitor_role === 'security' ||
      visitor_role === 'host' ||
      visitor_role === 'client'
    ) {
      const check = visitor_ability.some(
        (i) =>
          myAbilitySubject.includes(i.subject) ||
          myAbilityAction.includes(i.action)
      )
      if (check) {
        return next()
      }
      return next({ name: 'ptsal-protections' })
    }
    return next({ name: 'ptsal-protections' })
  }
  return next({ name: 'ptsal-protections' })
}
const guardCustomV1 = (next, myAbilitySubject = [], myAbilityAction = []) => {
  console.log('[guardCustom] protections')
  if (isUserLoggedIn()) {
    const { visitor_role, visitor_ability } = getUserData()
    if (visitor_role === 'security' || visitor_role === 'host') {
      const check = visitor_ability.some(
        (i) =>
          myAbilitySubject.includes(i.subject) ||
          myAbilityAction.includes(i.action)
      )
      if (check) {
        return next()
      }
      return next({ name: 'ptsal-protections' })
    }
    return next({ name: 'ptsal-protections' })
  }
  return next({ name: 'login' })
}

export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/KnowledgeBase.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/planning/approval/tamu',
    name: 'approval-tamu',
    component: () => import('@/views/planning/approval/visitor-umum/index.vue'),
    meta: {
      pageTitle: 'Planning Approval',
      breadcrumb: [
        {
          text: 'Approval',
          active: true
        },
        {
          text: 'Tamu ( Umum )',
          active: true
        }
      ],
      navActiveLink: 'approval-tamu'
    }
  },
  {
    path: '/planning/approval/tamu-history',
    name: 'approval-tamu-history',
    component: () => import('@/views/planning/approval/history/index.vue'),
    meta: {
      pageTitle: 'History of Approval',
      breadcrumb: [
        {
          text: 'Approval',
          active: true
        },
        {
          text: 'Tamu ( Umum )',
          active: true
        },
        {
          text: 'History',
          active: true
        }
      ],
      navActiveLink: 'approval-tamu-history'
    }
  },
  {
    path: '/planning/create-form-umum',
    name: 'create-form-umum',
    component: () => import('@/views/planning/CreateFormUmum.vue'),
    meta: {
      pageTitle: 'Create Form Umum',
      breadcrumb: [
        {
          text: 'Planning',
          active: true
        },
        {
          text: 'Form untuk Rencana memasuki Area PT SAL ( Guest Book )',
          active: true
        }
      ],
      navActiveLink: 'create-form-umum'
    }
  },
  {
    path: '/planning/create-form-khusus',
    name: 'create-form-khusus',
    component: () => import('@/views/planning/CreateFormKhusus.vue'),
    meta: {
      pageTitle: 'Create Form Khusus',
      breadcrumb: [
        {
          text: 'Planning',
          active: true
        },
        {
          text: 'Form untuk Rencana memasuki Area PT SAL ( Guest Book )',
          active: true
        }
      ],
      navActiveLink: 'create-form-khusus',
      guard: (next) => guardCustom(next, ['all'], ['create-form-khusus'])
    }
  },
  {
    path: '/planning/reuse-qrcode',
    name: 'reuse-qrcode',
    component: () => import('@/views/planning/ReuseQR.vue'),
    meta: {
      pageTitle: 'Reuse QRCode',
      breadcrumb: [
        {
          text: 'Planning',
          active: true
        },
        {
          text: 'Menggunakan data yang pernah dibuat untuk memasuki area PT SAL ( Guest Book )',
          active: true
        }
      ],
      navActiveLink: 'reuse-qrcode'
    }
  },
  {
    path: '/planning/create-form-umum/success',
    name: 'create-form-umum-success',
    component: () => import('@/views/planning/Success.vue'),
    meta: {
      layout: 'full'
    },
    beforeEnter: (to, from, next) => {
      if (
        store.state.visitor.successData &&
        (store.state.visitor.successData.full_name ||
          store.state.visitor.successData.phone)
      ) {
        console.log('state', store.state.visitor.successData)
        next() // Allow navigation if data exists
      } else {
        console.log('state', store.state.visitor.successData) // Log in case of failure
        next({ path: '/error-404' }) // Redirect to an error page if data is missing
      }
    }
  },
  {
    path: '/planning/create-form-khusus/success',
    name: 'create-form-khusus-success',
    component: () => import('@/views/planning/SuccessKhusus.vue'),
    meta: {
      layout: 'full'
    },
    beforeEnter: (to, from, next) => {
      if (
        store.state.visitor.successData &&
        (store.state.visitor.successData.full_name ||
          store.state.visitor.successData.phone)
      ) {
        console.log('state', store.state.visitor.successData)
        next() // Allow navigation if data exists
      } else {
        console.log('state', store.state.visitor.successData) // Log in case of failure
        next({ path: '/error-404' }) // Redirect to an error page if data is missing
      }
    }
  },
  {
    path: '/planning/reuse-qrcode/success',
    name: 'reuse-qrcode-success',
    component: () => import('@/views/planning/Success.vue'),
    meta: {
      layout: 'full'
    },
    beforeEnter: (to, from, next) => {
      if (
        store.state.visitor.successData &&
        (store.state.visitor.successData.full_name ||
          store.state.visitor.successData.phone)
      ) {
        console.log('state', store.state.visitor.successData)
        next() // Allow navigation if data exists
      } else {
        console.log('state', store.state.visitor.successData) // Log in case of failure
        next({ path: '/error-404' }) // Redirect to an error page if data is missing
      }
    }
  },

  {
    path: '/setting/profile',
    name: 'page-setting-profile',
    component: () => import('@/views/settings/profile/Profile.vue')
  },

  /* misscelenessadasd */
  {
    path: '/ptsal-protections',
    name: 'ptsal-protections',
    component: () => import('@/views/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '*',
    redirect: 'error-404'
  }
]
