import { getUserData, isUserLoggedIn } from '@/auth/utils'

const guardCustom = (next, myAbilitySubject = [], myAbilityAction = []) => {
  if (isUserLoggedIn()) {
    const { visitor_role, visitor_ability } = getUserData()
    if (visitor_role === 'client' || visitor_role === 'host') {
      const check = visitor_ability.some(
        (i) =>
          myAbilitySubject.includes(i.subject) ||
          myAbilityAction.includes(i.action)
      )
      if (check) {
        return next()
      }
      return next({ name: 'ptsal-protections' })
    }
    return next({ name: 'ptsal-protections' })
  }
  return next({ name: 'ptsal-protections' })
}

export default [
  {
    path: '/induction',
    name: 'induction',
    component: () => import('@/views/induction/index.vue'),
    meta: {
      pageTitle: 'All Visitor Induction',
      breadcrumb: [
        {
          text: 'induction',
          active: true
        },
        {
          text: 'index',
          active: true
        }
      ],
      navActiveLink: 'induction',
      guard: (next) => guardCustom(next, ['all'], ['team-induction'])
    }
  },
  {
    path: '/induction/in-area',
    name: 'induction-in-area',
    component: () => import('@/views/induction/in-area/index.vue'),
    meta: {
      pageTitle: 'Visitor IN Area Induction',
      breadcrumb: [
        {
          text: 'induction',
          active: true
        },
        {
          text: 'alert in-area',
          active: true
        }
      ],
      navActiveLink: 'induction-in-area',
      guard: (next) => guardCustom(next, ['all'], ['team-induction'])
    }
  },
  {
    path: '/induction/planning',
    name: 'induction-planning',
    component: () => import('@/views/induction/planning/index.vue'),
    meta: {
      pageTitle: 'Visitor Planning Induction',
      breadcrumb: [
        {
          text: 'induction',
          active: true
        },
        {
          text: 'alert induction | planning',
          active: true
        }
      ],
      navActiveLink: 'induction-planning',
      guard: (next) => guardCustom(next, ['all'], ['visitor-khusus'])
    }
  }
]
