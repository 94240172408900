import Vue from 'vue'
import {
  ToastPlugin,
  ModalPlugin,
  PaginationPlugin,
  BootstrapVue
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import router from './router'
import store from './store'
import App from './App.vue'

// Axios Mock Adapter
import '@/@fake-db/db'

// Global Components
import './global-components'

// 3rd party plugins
import firebase from 'firebase/app'
import i18n from './libs/i18n'
import '@/libs/acl'
import '@/libs/clipboard'
import './vee-validate'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import VueTour from 'vue-tour'

// Vue Tour
require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(PaginationPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)

// Composition API
Vue.use(VueCompositionAPI)
Vue.directive('uppercase', {
  bind(el, _, vnode) {
    el.addEventListener('input', (e) => {
      e.target.value = e.target.value.toUpperCase()
      vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
    })
  }
})

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
Vue.prototype.$alert = function (props) {
  this.$toast({
    component: ToastificationContent,
    position: 'top-right',
    props
  })
}

Vue.prototype.$variantBadgeKunjungan = {
  /* eslint-disable key-spacing */
  SAL: 'success',
  PIL: 'light-success',
  SUL: 'light-info',
  SPL: 'light-primary',
  PATRA: 'light-warning',
  TEM: 'light-success',
  SLB: 'light-info',
  PES: 'light-primary'
  /* eslint-enable key-spacing */
}

Vue.prototype.$i18 = i18n

// Initialize Firebase
import 'firebase/messaging'
// firebase
firebase.initializeApp({
  apiKey: 'AIzaSyCefyxZYj-RluHGO43Y-NqM8AJjLGWEH5o',
  authDomain: 'project-5566467322804966315.firebaseapp.com',
  databaseURL: 'https://project-5566467322804966315.firebaseio.com',
  projectId: 'project-5566467322804966315',
  storageBucket: 'project-5566467322804966315.appspot.com',
  messagingSenderId: '364839005563',
  appId: '1:364839005563:web:c439d4a2478a2d8ff121de',
  measurementId: 'G-QBNCYVSL7E'
})

navigator.serviceWorker
  .register('firebase-messaging-sw.js')
  .then((registration) => {
    const messaging = firebase.messaging()
    messaging.useServiceWorker(registration)
    console.log('success to register sw firebase-messaging-sw.js: ')
  })
  .catch((err) => {
    console.log('an error to register sw firebase-messaging-sw.js: ', err)
  })
// Global set firebase prototype so you can access Firebase instance anywhere
Vue.prototype.$viFirebase = firebase
// fiebase configs end

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
